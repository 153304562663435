<template>
  <v-container fluid class="ma-0">
    <v-row no-gutters justify="center">
      <v-col
        cols="12"
        class="text-center mt-10 mb-16 reform_primary--text text-h5 font-weight-bold"
      >
        온라인 상담 설정
      </v-col>
      <v-col cols="12" md="9" class="text-center mb-16">
        <div>
          <b>카카오톡 채널 링크</b>를 입력하시면 소개페이지 우측에
          <b>카카오톡 상담 버튼</b>이 보여집니다.
        </div>
        <div>
          <b>온라인 예약 사용 여부</b> 활성화시, 소개페이지 우측에
          <b>온라인 문의 예약 버튼</b>이 보여집니다.
        </div>
        <div>
          <b>온라인 예약 조회</b>는 <b>상담요청 조회</b>에서 확인 가능합니다.
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center">
      <v-col cols="12" md="9" class="b-2-b">
        <v-row no-gutters class="bb-1" align="center">
          <!-- 카카오톡 주소 -->
          <v-col
            cols="12"
            md="3"
            class="fit-col col-left text-h6 font-weight-bold pt-12 pb-12 pl-5"
          >
            <div>카카오톡 채널</div>
          </v-col>
          <v-col cols="12" md="9" class="fit-col pa-5">
            <v-text-field
              hide-details
              dense
              v-model="linkText"
              outlined
              placeholder="카카오톡 채널 링크를 입력해주세요"
              color="reform_primary"
            >
            </v-text-field>
            <div class="grey--text">* 예시) https://pf.kakao.com/.....</div>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <!-- 상담 동의 -->
          <v-col
            cols="12"
            md="3"
            class="fit-col col-left text-h6 font-weight-bold pt-12 pb-12 pl-5"
          >
            <div>온라인 예약 사용 여부</div>
          </v-col>
          <v-col cols="12" md="9" class="fit-col pa-5">
            <v-row no-gutters justify="start">
              <v-col cols="auto">
                <v-switch
                  hide-details
                  class="pt-0 mt-0"
                  color="reform_primary"
                  inset
                  :label="switchVal ? '사용' : '사용 안함'"
                  v-model="switchVal"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          color="reform_primary"
          dark
          rounded
          elevation="0"
          height="50px"
          width="120px"
          x-large
          class="mt-5 mb-16"
          @click="submit"
          >저장
        </v-btn>
      </v-col>
    </v-row>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";

export default {
  components: {
    DefaultPopup,
  },
  data() {
    return {
      switchVal: false,
      linkText: "",
      popupSet: {},
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters("book", ["GET_APP_DATA"]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapMutations("book", ["SET_APP_DATA"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("book", ["GET_APPOINTMENT_DATA", "UPDATE_APPOINTMENT_DATA"]),
    async initData() {
      await this.GET_APPOINTMENT_DATA().then((data) => {
        this.switchVal = this.GET_APP_DATA.bookAgrYn === "Y" ? true : false;
        this.linkText = this.GET_APP_DATA.kakaoUrl;
      });
    },
    async submit() {
      // if (!this.check_link()) return;
      let url = this.check_link();
      if (url === "denied") return;
      const param = {
        mapType: this.switchVal ? "Y" : "N",
        url3: url,
      };
      try {
        await this.UPDATE_APPOINTMENT_DATA(param).then((data) => {
          this.popupActive(
            "상담 정보 저장 완료",
            "상담 정보 저장이 완료되었습니다."
          );
        });
      } catch {
        this.popupActive(
          "상담 정보 저장 실패",
          "상담 정보를 저장하지 못했습니다"
        );
      }
    },
    check_link() {
      if (this.linkText === null || this.linkText === undefined)
        return this.GET_APP_DATA.kakaoUrl;
      if (this.linkText.length < 0 || this.linkText.length > 200) {
        return this.GET_APP_DATA.kakaoUrl;
      }
      if (
        !this.linkText.includes("https://pf.kakao.com") &&
        this.linkText !== ""
      ) {
        this.popupActive(
          "주소가 올바르지 않습니다.",
          "올바른 경로를 입력해 주세요.\n 예시) https://pf.kakao.com/....."
        );
        return "denied";
      }
      return this.linkText;
    },
    popupActive(
      title,
      content,
      active = true,
      type = "warn",
      btntext = "확인"
    ) {
      this.SET_POPUP(active);
      this.popupSet.title = title;
      this.popupSet.content = content;
      this.popupSet.popType = type;
      this.popupSet.cancelBtnText = btntext;
    },
  },
};
</script>

<style scoped lang="scss">
.bb-1 {
  border-bottom: 1px solid grey;
}
.bt-1 {
  border-top: 1px solid grey;
}
.b-2-b {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.fit-col {
  height: 100%;
}
.col-left {
  background-color: #f5f9fa;
}
</style>
